import React, { Component } from "react";
import classes from "./Home.module.scss";
import Layout from "../../layout/Main";
import SliderBanner from "../../components/SliderBanner";
import images from "../../commons/images";
import LoaderChase from "../../elements/LoaderChase";
import NotFound from "../../elements/NotFound";
import ListTracking from "../../components/ListTracking";
import { http } from "../../utility/http";
const slider = [
  { src: images.imgSlide1, title: "Input your marking number" },
  { src: images.imgSlide2, title: "Keep tracking your item’s ETA" },
  {
    src: images.imgSlide3,
    title: "Keep calm and wait ’til your item arrived",
  },
];

export default class Home extends Component {
  state = {
    markNumber: "",
    loading: false,
    totalFound: 0,
    foundOrder: false,
    foundArrived: false,
    dataOrder: null,
    dataArrived: null,
    onSearch: false,
  };

  onSearchHandler = async () => {
    const { markNumber } = this.state;
    console.log(encodeURIComponent(markNumber));
    if (markNumber.length < 1) {
      return;
    }
    this.setState({ loading: true, onSearch: true, totalFound: 0 });
    await this.getDataArrived(encodeURIComponent(markNumber.trim()));
    await this.getDataDelivery(encodeURIComponent(markNumber.trim()));
  };

  getDataArrived = async (markNumber) => {
    console.log(decodeURIComponent(markNumber));
    let params = {
      method: "GET",
      path: `cargo`,
      query: {
        marking_code: markNumber,
        status: "1",
        limit: 10,
      },
    };
    const res = await http(params);
    console.log("arrived", res);
    if (res) {
      if (res.success) {
        setTimeout(() => {
          this.setState({
            foundArrived: res.response.payload.rows.length > 0 ? true : false,
            dataArrived:
              res.response.payload.rows.length > 0
                ? res.response.payload
                : null,
            totalFound:
              res.response.payload.rows.length > 0
                ? this.state.totalFound + res.response.payload.rows.length
                : this.state.totalFound,
          });
        }, 300);
      } else {
        this.setState({
          onSearch: true,
          foundArrived: false,
        });
      }
    }
  };

  getDataDelivery = async (markNumber) => {
    let params = {
      method: "GET",
      path: `cargo`,
      query: { marking_code: markNumber, status: "0", limit: 9999 },
    };
    const res = await http(params);
    console.log("delivery", res);
    if (res) {
      if (res.success) {
        setTimeout(() => {
          this.setState({
            loading: false,
            foundOrder: res.response.payload.rows.length > 0 ? true : false,
            dataOrder:
              res.response.payload.rows.length > 0
                ? res.response.payload
                : null,
            totalFound:
              res.response.payload.rows.length > 0
                ? this.state.totalFound + res.response.payload.rows.length
                : this.state.totalFound,
          });
        }, 300);
      } else {
        this.setState({
          onSearch: true,
          loading: false,
          foundOrder: false,
        });
      }
    }
  };

  onChangeHandler = ({ target: { name, value } }) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        console.log(this.state.markNumber);
        if (this.state.markNumber.length < 1) {
          this.setState({ onSearch: false });
        }
      }
    );
  };

  render() {
    const {
      onSearch,
      loading,
      foundOrder,
      foundArrived,
      dataOrder,
      dataArrived,
      totalFound,
    } = this.state;
    return (
      <Layout
        classCustom={classes.home}
        markNumber={this.state.markNumber}
        onChangeHandler={this.onChangeHandler}
        onSearchHandler={this.onSearchHandler}
      >
        {onSearch ? (
          loading ? (
            <LoaderChase classCustom={classes.loaderChase} />
          ) : foundOrder || foundArrived ? (
            <ListTracking
              totalFound={totalFound}
              dataOrder={dataOrder}
              dataArrived={dataArrived}
            />
          ) : (
            <NotFound />
          )
        ) : (
          <div className={classes.slider}>
            <SliderBanner data={slider} />
          </div>
        )}
      </Layout>
    );
  }
}
