import React from "react";
import classes from "./LoaderChase.module.scss";
import classNames from "classnames";
const LoaderChase = ({ classCustom }) => {
  return (
    <div className={classNames(classes.loaderChase, classCustom)}>
      <div className={classes.loader}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default LoaderChase;
