import React from "react";
import classes from "./ListTracking.module.scss";
import CardTracking from "../CardTracking";
import images from "../../commons/images";

const ListTracking = ({ totalFound, dataOrder, dataArrived }) => {
  const dataTrack = [
    {
      content: "Perlengkapan menjahit, bahan tekstil dari Guangzhou",
      date_in: "30 Jun 2020, 08:23 WIB",
      eta: "19 Jul 2020",
      measure: {
        kg: 58,
        cbm: 6,
      },
      status: 0,
    },
    {
      content: "Perlengkapan menjahit, bahan tekstil dari Guangzhou",
      date_in: "01 Jul 2020, 03:58 WIB",
      eta: "25 Jul 2020",
      measure: {
        kg: 72,
        cbm: 8,
      },
      status: 0,
    },
    {
      content: "Perlengkapan menjahit, bahan tekstil dari Guangzhou",
      date_in: "29 May 2020, 23:19 WIB",
      eta: "21 Jun 2020",
      measure: {
        kg: 46,
        cbm: 3,
      },
      status: 1,
    },
  ];
  return (
    <div className={classes.listTracking}>
      <div className={classes.info}>
        <div className={classes.content}>
          <div className={classes.imgCont}>
            <img
              src={
                dataOrder
                  ? dataOrder.rows[0].type === "sea"
                    ? images.iconLaut
                    : images.iconUdara
                  : dataArrived.rows[0].type === "sea"
                  ? images.iconLaut
                  : images.iconUdara
              }
              alt="Udara"
            />
          </div>
          <p>
            {dataOrder
              ? dataOrder.rows[0].type === "sea"
                ? "Laut"
                : "Udara"
              : dataArrived.rows[0].type === "sea"
              ? "Laut"
              : "Udara"}
          </p>
        </div>
        <p className={classes.found}>{totalFound} Item(s)</p>
      </div>
      {dataOrder &&
        dataOrder.rows.map((item, index) => (
          <CardTracking
            key={index}
            data={item}
            classCustom={classes.itemTrack}
            isSea={dataOrder.rows[0].type === "sea"}
          />
        ))}
      {dataArrived &&
        dataArrived.rows.map((item, index) => (
          <CardTracking
            key={index}
            data={item}
            classCustom={classes.itemTrack}
            isSea={dataArrived.rows[0].type === "sea"}
          />
        ))}
    </div>
  );
};

export default ListTracking;
