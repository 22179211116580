import React from "react";
import classes from "./Header.module.scss";
import images from "../../commons/images";
import InputApp from "../../elements/InputApp";
const Header = ({
  markNumber,
  onChangeHandler,
  onSearchHandler,
  refComponent,
}) => {
  return (
    <div className={classes.header} ref={refComponent}>
      <div className={classes.logo}>
        <img src={images.logoPelican} alt="pelicanExpress" />
      </div>
      <div className={classes.container}>
        <h1>Track your Cargo</h1>
        <p>Enter your marking number below</p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSearchHandler();
          }}
        >
          <InputApp
            label="eg. PPPXXX56789"
            classCustom={classes.searchbar}
            icon={true}
            value={markNumber}
            srcIcon={images.iconSearch}
            clickIconHandle={onSearchHandler}
            alignIcon="right"
            onChangeHandle={(e) =>
              onChangeHandler({
                target: {
                  value: e.target.value,
                  name: "markNumber",
                },
              })
            }
          />
        </form>
        {/* <InputApp
            label="eg. PEXHHN23951"
            classCustom={classes.searchbar}
            icon={true}
            value={markNumber}
            srcIcon={images.iconSearch}
            clickIconHandle={onSearchHandler}
            alignIcon="right"
            onChangeHandle={(e) =>
              onChangeHandler({
                target: {
                  value: e.target.value,
                  name: "markNumber",
                },
              })
            }
          /> */}
      </div>
    </div>
  );
};

export default Header;
