import React from "react";
import classes from "./NotFound.module.scss";
import images from "../../commons/images";

const NotFound = () => {
  return (
    <div className={classes.notFound}>
      <div className={classes.container}>
        <div className={classes.imgCont}>
          <img src={images.iconNotFound} alt="not found" />
        </div>
        <h3>Oops, marking number not found</h3>
        <p>Try searching again by using a different marking number</p>
      </div>
    </div>
  );
};

export default NotFound;
