import React from "react";
import classes from "./Footer.module.scss";
import images from "../../commons/images";
import history from "../../utility/history";

const Footer = () => {
  return (
    <div className={classes.footer}>
      <div className={classes.social}>
        <p>Follow our</p>
        <div
          className={classes.imgSocial}
          onClick={() =>
            (window.location.href = "https://www.instagram.com/pelican_xpress/")
          }
        >
          <img src={images.iconIg} alt="instagram" />
        </div>
        <p>for more info</p>
      </div>
      <p className={classes.copy}>
        © 2020 Pelican Express. All rights reserved.
      </p>
    </div>
  );
};

export default Footer;
