import React, { Component } from "react";
import classes from "./Main.module.scss";
import classNames from "classnames";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class Main extends Component {
  constructor(props) {
    super(props);
    this.searchBarRef = React.createRef();
  }
  state = {
    loaded: false,
    searchBarPosition: 0,
    stickHeader: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      searchBarPosition: this.searchBarRef.current.offsetTop,
    });
    document.addEventListener("scroll", this.checkSearchBarPosition, {
      passive: true,
    });
  }

  checkSearchBarPosition = () => {
    const top = window.pageYOffset;
    if (top < this.state.categoryPosition) {
      this.setState({
        stickHeader: false,
      });
    } else {
      this.setState({
        stickHeader: true,
      });
    }
    console.log(this.searchBarRef.current.offsetTop);
    this.setState({
      categoryHeight: this.searchBarRef.current.clientHeight,
    });
  };

  render() {
    const {
      children,
      classCustom,
      markNumber,
      onChangeHandler,
      onSearchHandler,
    } = this.props;
    return (
      <div className={classes.app}>
        <Header
          refComponent={this.searchBarRef}
          markNumber={markNumber}
          onChangeHandler={onChangeHandler}
          onSearchHandler={onSearchHandler}
        />
        <div className={classNames(classes.main, classCustom)}>{children}</div>
        <Footer />
      </div>
    );
  }
}

export default Main;
