import React from "react";
import classes from "./CardTracking.module.scss";
import classNames from "classnames";
import { formatNumberDisplay, formatDate } from "../../utility";

const CardTracking = ({ data, classCustom, isSea }) => {
  return (
    <div
      className={classNames(classes.cardTracking, classCustom, {
        [classes.arrived]: data.status,
      })}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <h4>
            {isSea ? (
              <>
                {formatNumberDisplay(data.cubic)} cbm /{" "}
                {formatNumberDisplay(data.weight)} kg{" "}
                {data.ctns && `/ ${formatNumberDisplay(data.ctns)} ctns`}
              </>
            ) : (
              <>
                {formatNumberDisplay(data.weight)} kg /{" "}
                {formatNumberDisplay(data.cubic)} cbm{" "}
                {data.ctns && `/ ${formatNumberDisplay(data.ctns)} ctns`}
              </>
            )}
          </h4>
          <h5>{data.status ? "Arrived" : "Delivery"}</h5>
        </div>
        <div className={classes.info}>
          <div className={classes.date}>
            <p>Tanggal Masuk Gudang</p>
            <p>{formatDate(data.warehouse_entry * 1000)}</p>
          </div>
          <div className={classes.eta}>
            <p>ETA</p>
            <p>{formatDate(data.ETA * 1000)}</p>
            {/* <p>{data.eta}</p> */}
          </div>
        </div>
        <div
          className={classes.content}
          dangerouslySetInnerHTML={{ __html: data.description }}
        ></div>
        <p className={classes.tracking_number}>
            No. Resi : {data.receipt_number ? data.receipt_number : "-"}
        </p>
      </div>
    </div>
  );
};

export default CardTracking;
