import React, { useState } from "react";
import classes from "./InputApp.module.scss";
import classNames from "classnames";
import images from "../../commons/images";

const InputApp = ({
  type = "text",
  value,
  label,
  name,
  mTop = 10,
  mLeft = 0,
  icon = type === "password" ? true : false,
  multipleIcon,
  alignIcon = type === "password" ? "right" : "left",
  srcIcon = images.iconSearch,
  src2Icon = images.iconCloseCircle,
  onChangeHandle,
  onClickForm,
  clickIconHandle,
  clickIconHandle2,
  classCustom,
  refInput,
  disabled,
}) => {
  const [showPass, setShowPass] = useState(false);
  const toggleShowPass = () => {
    setShowPass(!showPass);
  };
  return (
    <div
      style={{
        marginTop: mTop + "px",
        marginLeft: mLeft + "px",
      }}
      className={classNames(
        classes.inputPlc,
        [
          icon && [
            multipleIcon
              ? classes.multiple
              : [
                  alignIcon === "left"
                    ? classes.alignIconLeft
                    : classes.alignIconRight,
                ],
          ],
        ],
        classCustom
      )}
    >
      {icon && (
        <div
          className={classNames(classes.imgCont, {
            [classes.password]: type === "password",
          })}
          onClick={
            type === "password"
              ? toggleShowPass
              : clickIconHandle
              ? clickIconHandle
              : ""
          }
        >
          <img
            src={
              type === "password"
                ? showPass
                  ? images.iconUnshow
                  : images.iconShow
                : srcIcon
            }
            alt="icon"
          />
        </div>
      )}
      {multipleIcon && (
        <div
          className={classNames(classes.imgCont, classes.imgCont2, {
            [classes.pointer]: clickIconHandle2,
          })}
          onClick={clickIconHandle2 ? clickIconHandle2 : ""}
        >
          <img src={src2Icon} alt="icon" />
        </div>
      )}
      <input
        ref={refInput ? refInput : null}
        className={type === "password" ? classes.password : null}
        type={showPass ? "text" : type}
        value={value}
        name={name}
        placeholder={label}
        disabled={disabled}
        onChange={onChangeHandle}
        onClick={onClickForm}
      />
    </div>
  );
};

export default InputApp;
