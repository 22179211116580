import logoPelican from "../assets/images/logo-pelican@3x.png";
import logoPelicanPre from "../assets/images/logo-pelican.png";
import imgSlide1 from "../assets/images/img-slide-1@3x.png";
import imgSlide1Pre from "../assets/images/img-slide-2.png";
import imgSlide2 from "../assets/images/img-slide-2@3x.png";
import imgSlide2Pre from "../assets/images/img-slide-2.png";
import imgSlide3 from "../assets/images/img-slide-3@3x.png";
import imgSlide3Pre from "../assets/images/img-slide-3.png";

import iconShow from "../assets/images/icon-show@3x.png";
import iconShowPre from "../assets/images/icon-show.png";
import iconIg from "../assets/images/icon-ig@3x.png";
import iconIgPre from "../assets/images/icon-ig.png";
import iconSearch from "../assets/images/icon-search@3x.png";
import iconSearchPre from "../assets/images/icon-search.png";
import iconNotFound from "../assets/images/icon-not-found@3x.png";
import iconNotFoundPre from "../assets/images/icon-not-found.png";
import iconUdara from "../assets/images/icon-udara@3x.png";
import iconUdaraPre from "../assets/images/icon-udara.png";
import iconLaut from "../assets/images/icon-laut@3x.png";
import iconLautPre from "../assets/images/icon-laut.png";

export default {
  logoPelican,
  logoPelicanPre,
  imgSlide1,
  imgSlide1Pre,
  imgSlide2,
  imgSlide2Pre,
  imgSlide3,
  imgSlide3Pre,
  iconShow,
  iconShowPre,
  iconIg,
  iconIgPre,
  iconSearch,
  iconSearchPre,
  iconNotFound,
  iconNotFoundPre,
  iconUdara,
  iconUdaraPre,
  iconLaut,
  iconLautPre,
};
