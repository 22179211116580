import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import classes from "./SliderBanner.module.scss";
import "./SliderBanner.css";
import classNames from "classnames";

export default ({ data, wArrow, arrowOption, classCustom }) => {
  const [currSlide, setCurrSlide] = useState(0);
  const settings = {
    infinite: false,
    dots: true,
    centerPadding: "20px",
    slidesToShow: 1,
    beforeChange: (prev, next) => {
      setCurrSlide(next);
    },
    appendDots: (dots) => {
      return (
        <ul>
          {dots.map((item, index) => {
            return <li key={index}>{item.props.children}</li>;
          })}
        </ul>
      );
    },
    customPaging: (index) => {
      return (
        <button className={index === currSlide ? classes.dotActive : null}>
          {index + 1}
        </button>
      );
    },
  };

  return (
    <div className={classNames(classCustom, classes.sliderContainer)}>
      <Slider
        {...settings}
        className={classNames(classes.sliderBanner, {
          [classes.wArrow]: wArrow,
          [classes.arrowOption]: arrowOption,
        })}
      >
        {data &&
          data.map((item, index) => {
            return (
              <div key={index} className={classNames(classes.sliderItem)}>
                <div className={classes.imgCont}>
                  <img src={item.src} alt={item.title} />
                </div>
                <p className={classes.content}>
                  <span>{index + 1}.</span>
                  {item.title}
                </p>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};
